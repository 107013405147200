import React, { useState } from 'react';
import './App.css';
import Nav from './components/nav/Nav.jsx';
import About from './components/about/About';
import Projects from './components/projects/Projects';
import Experience from './components/experience/Experience';

function App() {
  const [selection, makeSelection] = useState('');
  
  // let changeRender = (selection) =>{
  //   makeSelection(selection);
  //   console.log(selection);
  // }

  const toRender = () =>{
    if(selection==='about'){
      return <About />
    }
    if(selection==='projects'){
      return <Projects />
    }
    if(selection==='experience'){
      return <Experience />
    }
  }
  return (
    <div className="App">
      <main>
        <Nav makeSelection={makeSelection}/>
        {toRender()}
        <div className="footer" />
      </main>
    </div>
  );
}

export default App;
