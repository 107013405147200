import React, { useState } from "react";
import pokeball from "../../assets/pokeball.svg";
import snowman from "../../assets/snowman.svg";
import woman from "../../assets/woman.svg";
import Carousel from "react-bootstrap/Carousel";
import style from "./projects.css";

export default function Projects() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
      <div className="project-info">
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item interval={100000}>
        <img className="d-block w-100" src={snowman} alt="First slide" />
        {/* <div className="carousel-caption"> */}
        <Carousel.Caption>
          <h1>Meltman</h1>
          <h4>Winter themed hangman!</h4>
          {/* <h6>Works on modern desktop and mobile browsers.</h6> */}
        </Carousel.Caption>
        {/* </div> */}
      </Carousel.Item>
      <Carousel.Item interval={100000}>
        <img className="d-block w-100" src={woman} alt="Second slide" />

        <Carousel.Caption>
          <h1>FitnessApp</h1>
          <h4>
            Calorie and exercise tracker
          </h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000}>
        <img className="d-block w-100" src={pokeball} alt="Third slide" />

        <Carousel.Caption>
          <h1>PokeSearch</h1>
          <h4> Application for looking up pokemon data</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}
