import React from 'react'
import style from './nav.css';

export default function Nav(props) {
    let makeSelection=props.makeSelection;

    // let handleClick = (e) =>{
    //     changeRender(e);
    // }

    return (
        <>
        <nav>
            <ul>
                <li onClick={(e) => makeSelection("about")}>
                    about me
                </li>

                <li onClick={(e) => makeSelection("projects")}>
                    projects
                </li>

                <li onClick={(e) => makeSelection("experience")}>
                    experience
                </li>
                
            </ul>
            {/* // <ul class="li" onClick={(e) => makeSelection("about")}>about me</ul>
            // <ul class="li" onClick={(e) => makeSelection("projects")}>projects</ul>
            // <ul class="li" onClick={(e) => makeSelection("experience")}>experience</ul> */}
        </nav>
        </>
    )
}
